/*
These styles have been refactored from global.css
*/

.ql-priceContent {
    color: #122632;
    width: fit-content;
    background-color: #fafafa;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    padding: 10px 16px;
    margin: 0 0 15px;
}

.ql-editor ul > li {
    list-style: disc !important;
}

.ql-editor blockquote {
    font-style: italic;
}