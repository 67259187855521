/*
These styles have been refactored from global.css.
The original commit message stated "fixing the autoresize feature in code mirror"
but I'm not sure what exactly is supposed to be fixed here,
the component seems to work just fine without it. We will keep it anyway for now just to be safe ...
*/

.CodeMirror {
    height: auto !important;
    /* NOTE the overflow-x makes the code chip look ugly */
    /* overflow-x: auto !important; */
}

.CodeMirror-scroll {
    height: auto !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
}
