.app {
    font-family: Arial, sans-serif;
    padding: 20px;
}

h1 {
    text-align: center;
    color: #333;
}

.filters {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

label {
    font-size: 1rem;
}

select {
    margin-left: 10px;
    padding: 5px;
}

.deployments-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.deployments-table th,
.deployments-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.deployments-table th {
    background-color: #f4f4f4;
    color: #333;
}

.healthy {
    background-color: #e8f5e9;
}

.unhealthy {
    background-color: #ffebee;
}
